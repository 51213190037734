export default [
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Username',
    },
    cell: {
      type: 'text',
      value: (e) => e.username,
    },
  },
  {
    width: 20,
    header: {
      type: 'text',
      caption: 'Email',
    },
    cell: {
      type: 'text',
      value: (e) => e.email,
    },
  },
  {
    width: 20,
    header: {
      type: 'text',
      caption: 'Access',
    },
    cell: {
      type: 'text',
      value: (e) => e.role,
    },
  },
  {
    width: 60,
    header: {
      type: 'text',
      caption: 'Registration date',
      sort: 'regDate',
    },
    cell: {
      type: 'slot',
      name: 'created',
    },
  },
];
