//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Filters from './P2PArbitratorsListFilters.vue';
import UsersLock from '~/views/Users/modals/UsersLock.vue';
import UserSelect from '~/components/UserSelect.vue';

import tableConfig from './tableConfig';
import constant from '~/const';

export default {
  components: { Filters, UsersLock, UserSelect },

  data() {
    return {
      tableConfig,
      selection: [],
      loader: false,
      usersLock: {
        id: 0,
        modal: false,
      },
      changingUserPassword: {
        modal: false,
        id: 0,
        input: {
          newPassword: '',
        },
      },
      addingArbitrator: {
        modal: false,
        input: {
          userId: 0,
          role: null,
        },
      },
      constant,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('p2pArbitratorsList', ['page', 'pages', 'data', 'sort']),
    p2pRolesList: () => constant.p2p.USER_ROLE_LIST,
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('p2pArbitratorsList', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      grantUserAction: 'grantUser',
    }),
    ...mapActions('users', {
      usersLockAction: 'usersBlock',
      usersUnlockAction: 'usersUnblock',
      changeUserPasswordAction: 'changeUserPassword',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    isBlocked(row) {
      const { lockList } = row;
      if (!lockList) return false;

      return lockList.find((e) => e.type === constant.users.USER_LOCK_TYPE.GENERAL);
    },

    confirmResetPassword(id) {
      this.changingUserPassword.input.newPassword = '';
      this.changingUserPassword.modal = true;
      this.changingUserPassword.id = id;
    },

    async resetPassword() {
      const { id } = this.changingUserPassword;
      const { newPassword } = this.changingUserPassword.input;

      this.setGeneralProgress(true);
      try {
        this.changeUserPasswordAction({
          userId: id,
          newPassword,
        });
        this.setSuccessNotification('Password changed');
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.setGeneralProgress(false);
    },

    setupNfa(id) {
      const route = this.$router.resolve({
        name: 'user-security',
        params: { id },
      });
      window.open(route.href, '_blank');
    },

    confirmBlock(id) {
      this.usersLock.id = id;
      this.usersLock.modal = true;
    },

    removeRole(userId) {
      this.confirmAction({
        title: 'Are you sure you want to remove role?',
        hasPrompt: false,
        promptRequired: false,
        callback: async () => {
          this.loader = true;
          this.setGeneralProgress(true);
          try {
            await this.grantUserAction({
              userId,
              roles: 'User',
            });
            await this.loadDataAction({});
            this.setSuccessNotification('Role removed');
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.loader = false;
          this.setGeneralProgress(false);
        },
      });
    },

    async block({ comment }) {
      const { id } = this.usersLock;
      this.loader = true;
      this.setGeneralProgress(true);
      try {
        await this.usersLockAction({
          userIds: [id],
          comment,
          type: constant.users.USER_LOCK_TYPE.GENERAL,
        });
        await this.loadDataAction({});
        this.setSuccessNotification('User locked');
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.loader = false;
      this.setGeneralProgress(false);
    },

    confirmUnblock(id) {
      this.confirmAction({
        title: 'Are you sure you want to unlock user?',
        hasPrompt: true,
        promptRequired: true,
        callback: async ({ prompt }) => {
          this.loader = true;
          this.setGeneralProgress(true);
          try {
            await this.usersUnlockAction({
              userIds: [id],
              comment: prompt,
              type: constant.users.USER_LOCK_TYPE.GENERAL,
            });
            await this.loadDataAction({});
            this.setSuccessNotification('User unlocked');
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.loader = false;
          this.setGeneralProgress(false);
        },
      });
    },

    confirmAddArbitrator() {
      this.addingArbitrator.input.userId = 0;
      this.addingArbitrator.modal = true;
    },

    addArbitrator() {
      const { userId } = this.addingArbitrator.input;
      this.confirmAction({
        title: 'Are you sure you want to add a new role?',
        callback: async () => {
          this.loader = true;
          this.setGeneralProgress(true);
          try {
            await this.grantUserAction({
              userId,
              roles: constant.p2p.USER_ROLE.ARBITRATOR,
            });
            await this.loadDataAction({});
            this.addingArbitrator.modal = false;
            this.setSuccessNotification('New arbitrator added');
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.loader = false;
          this.setGeneralProgress(false);
        },
      });
    },
  },
};
